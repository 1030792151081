<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      Published resources
      <router-link slot="right" to="/" class="button"> Dashboard </router-link>
    </hero-bar>
    <section class="section is-main-section">
      <card-component>
        <em>
          <p class="pb-2">View all resources that are published by this Connector, when an IDS Broker is configured with automatic registration this information is also shared with the Broker.</p>
          <p class="pb-2">Click on a catalog to show the resources available, or add a new Resource.</p>
          <p><strong>Note:</strong> adding resources via the form below only adds metadata to the connector. For adding artifacts please use the <router-link to="/artifacts/provider">Artifact Provider</router-link>.</p>
        </em>
      </card-component>
      <card-component
        title="Catalogs"
        class="has-table has-mobile-sort-spaced"
        icon="file-document-multiple"
        headerColor="info"
      >
        <b-table
          :loading="isLoading"
          :paginated="paginated"
          :per-page="15"
          :striped="true"
          :hoverable="true"
          default-sort="name"
          :data="catalogsData"
          :selected.sync="selectedCatalog"
          :focusable="true"
        >
          <b-table-column
            label="Catalog ID"
            field="catalogId"
            sortable
            v-slot="props"
          >
            {{ props.row.catalogId }}
          </b-table-column>
          <b-table-column label="# Resources" field="numResources" sortable v-slot="props">
            {{ props.row.numResources }}
          </b-table-column>
          <b-table-column label="Actions" v-slot="props">
            <b-icon
              icon="delete"
              type="is-danger"
              class="is-clickable"
              @click.native="deleteCatalog(props.row.catalogId)"
            >
            </b-icon>
          </b-table-column>
          <section slot="empty" class="section">
            <div class="content has-text-grey has-text-centered">
              <p>No resource catalogs present...</p>
            </div>
          </section>
        </b-table>
      </card-component>
      <card-component
        v-if="selectedCatalog"
        title="Resources"
        class="has-table has-mobile-sort-spaced"
        icon="file-document-multiple"
        headerColor="info"
      >
        <b-table
          :paginated="paginated"
          :per-page="15"
          :striped="true"
          :hoverable="true"
          default-sort="name"
          :data="resourcesData"
        >
          <b-table-column
            label="Artifact ID"
            field="resourceId"
            sortable
            v-slot="props"
          >
            {{ props.row.resourceId }}
          </b-table-column>
          <b-table-column label="Title" field="title" sortable v-slot="props">
            {{ props.row.title }}
          </b-table-column>
          <b-table-column
            label="Created"
            field="created"
            sortable
            v-slot="props"
          >
            <template v-if="props.row.created">
              {{ props.row.created }}
            </template>
            <template v-else> - </template>
          </b-table-column>
          <b-table-column
            label="File name"
            field="filename"
            sortable
            v-slot="props"
          >
            <template v-if="props.row.filename">
              {{ props.row.filename }}
            </template>
            <template v-else> - </template>
          </b-table-column>
          <b-table-column
            label="Contract Offer"
            field="contractOffer"
            sortable
            v-slot="props"
          >
            <template v-if="props.row.contractOffer">
              <b-icon
                icon="file-search"
                type="is-info"
                class="is-clickable"
                @click.native="contractOfferModal = {isActive: true, offer: props.row.contractOffer}"
              >
              </b-icon>
            </template>
            <template v-else> - </template>
          </b-table-column>
          <b-table-column label="Actions" v-slot="props">
            <b-icon
              icon="delete"
              type="is-danger"
              class="is-clickable"
              @click.native="deleteResource(props.row.resourceId)"
            >
            </b-icon>
            <b-icon
              icon="file-search"
              type="is-info"
              class="is-clickable"
              @click.native="resourceMetadataModal = {isActive: true, resource: props.row.resource}"
            >
            </b-icon>
          </b-table-column>
          <section slot="empty" class="section">
            <div class="content has-text-grey has-text-centered">
              <p>No resources present...</p>
            </div>
          </section>
        </b-table>
      </card-component>
      <card-component
        title="Add new resource"
        icon="plus"
        headerColor="success"
      >
        <form @submit.prevent="addResource">
          <b-field label="Catalog" horizontal>
            <b-field>
            <b-select placeholder="Catalog" v-model="newResource.catalogId">
              <option v-for="catalog in catalogsData" :key="catalog.catalogId" :value="catalog.catalogId">
                {{ catalog.catalogId }}
              </option>
              <option key="new" value="new">
                New Catalog
              </option>
            </b-select>
            <b-input
              v-if="newResource.catalogId === 'new'"
              v-model="newResource.newCatalogId"
              placeholder="Catalog ID (optional, will be generated if not provided)"
            />
            </b-field>
          </b-field>
          <b-field label="Catalog Metadata" horizontal>
            <b-input
              v-model="newResource.resource"
              type="textarea"
              :placeholder="resourcePlaceholder"
              rows="10"
            />
          </b-field>
          <b-field horizontal>
            <b-field grouped>
              <div class="control">
                <b-button native-type="submit" type="is-primary"
                  >Add resource</b-button
                >
              </div>
              <div class="control">
                <b-button
                  type="is-primary is-outlined"
                  @click="
                    () => {
                      newResource = {
                        catalogId: null,
                        newCatalogId: null,
                        resource: null
                      };
                    }
                  "
                  >Reset</b-button
                >
              </div>
            </b-field>
          </b-field>
        </form>
      </card-component>
      <b-modal :active.sync="contractOfferModal.isActive" has-modal-card>
            <div class="modal-card" style="width: auto">
                <header class="modal-card-head">
                    <p class="modal-card-title">Contract Offer</p>
                </header>
                <section class="modal-card-body">
                  <vue-json-editor v-model="contractOfferModal.offer" :modes="['view', 'text']" mode="view" :show-btns="false" :expandedOnStart="true"></vue-json-editor>
                </section>
                <footer class="modal-card-foot">
                    <button class="button" type="button" @click="contractOfferModal.isActive = false">Close</button>
                </footer>
            </div>
        </b-modal>
      <b-modal :active.sync="resourceMetadataModal.isActive" has-modal-card>
            <div class="modal-card" style="width: auto">
                <header class="modal-card-head">
                    <p class="modal-card-title">Resource metadata</p>
                </header>
                <section class="modal-card-body">
                  <vue-json-editor v-model="resourceMetadataModal.resource" :modes="['view', 'text']" mode="view" :show-btns="false" :expandedOnStart="true"></vue-json-editor>
                </section>
                <footer class="modal-card-foot">
                    <button class="button" type="button" @click="resourceMetadataModal.isActive = false">Close</button>
                </footer>
            </div>
        </b-modal>
    </section>
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar'
import CardComponent from '@/components/CardComponent'
import HeroBar from '@/components/HeroBar'
import { mapState } from 'vuex'
import moment from 'moment-timezone'
import vueJsonEditor from 'vue-json-editor'

export default {
  name: 'Resources',
  components: {
    HeroBar,
    CardComponent,
    TitleBar,
    vueJsonEditor
  },
  data () {
    return {
      isLoading: false,
      paginated: true,
      resourceMetadataModal: {
        isActive: false,
        resource: null
      },
      contractOfferModal: {
        isActive: false,
        offer: null
      },
      resourcePlaceholder: `{
  "@type": "ids:Resource",
  "@id": "https://w3id.org/idsa/autogen/resource/00000000-0000-0000-0000-000000000000",
  ...
}`,
      catalogs: [],
      selectedCatalog: null,
      newResource: {
        catalogId: null,
        newCatalogId: null,
        resource: null
      },
      interval: null
    }
  },
  computed: {
    titleStack () {
      return ['Self Description', 'Resources']
    },
    catalogsData () {
      return this.catalogs.map((catalog) => {
        var created = catalog['ids:created']?.['@value']
        if (created) {
          created = moment(catalog).tz(moment.tz.guess()).format('DD-MM-YYYY HH:mm:ss z')
        }
        return {
          catalogId: catalog['@id'],
          numResources: catalog['ids:offeredResource']?.length || 0
        }
      })
    },
    resourcesData () {
      return this.catalogs.filter(catalog => catalog['@id'] === this.selectedCatalog.catalogId)?.[0]?.['ids:offeredResource']?.map((resource) => {
        var created = resource['ids:created']?.['@value']
        if (created) {
          created = moment(created).tz(moment.tz.guess()).format('DD-MM-YYYY HH:mm:ss z')
        }
        return {
          resourceId: resource['@id'],
          title: resource['ids:title']?.[0]?.['@value'],
          contractOffer: resource['ids:contractOffer']?.[0],
          filename: resource['ids:representation']?.[0]?.['ids:instance']?.[0]?.['ids:fileName'],
          created: created,
          resource: resource
        }
      }) || []
    },
    ...mapState(['api'])
  },
  async created () {
    this.interval = setInterval(this.getCatalogs, 30000)
    await this.getCatalogs()
  },
  beforeDestroy () {
    clearInterval(this.interval)
  },
  methods: {
    async getCatalogs () {
      try {
        const catalogs = await this.api.get('/resources')
        this.catalogs = catalogs.data
      } catch (e) {
        clearInterval(this.interval)
        this.$buefy.snackbar.open({
          message: `Error: ${e.message}`,
          type: 'is-danger',
          position: 'is-bottom',
          duration: 10000,
          queue: false,
          cancelText: 'OK',
          actionText: 'Retry',
          onAction: () => {
            clearInterval(this.interval)
            this.getCatalogs()
            this.interval = setInterval(this.getCatalogs, 30000)
          }
        })
        console.log(e)
      }
    },
    async addResource () {
      try {
        const catalogId = this.newResource.catalogId === 'new' ? this.newResource.newCatalogId || `https://w3id.org/idsa/autogen/resourceCatalog/${this.uuidv4()}` : this.newResource.catalogId
        await this.api.post(`/resources/${encodeURIComponent(catalogId)}`, this.newResource.resource, {
          headers: {
            'Content-Type': 'application/ld+json'
          }
        })
        this.newResource = {
          catalogId: null,
          newCatalogId: null,
          resource: null
        }
        await this.getCatalogs()
        this.$buefy.toast.open({
          message: 'Resource successfully added',
          type: 'is-success',
          position: 'is-bottom'
        })
      } catch (e) {
        this.$buefy.toast.open({
          message: `Error: ${e.message}`,
          type: 'is-danger',
          position: 'is-bottom'
        })
        console.log(e)
      }
    },
    async deleteCatalog (catalogId) {
      this.$buefy.dialog.confirm({
        title: 'Delete resource',
        message: `Are you sure you want to permanently delete the resource catalog ${catalogId} and all related resources?`,
        type: 'is-danger',
        onConfirm: async () => {
          try {
            await this.api.delete(
              `/resources/${encodeURIComponent(catalogId)}`
            )
            await this.getCatalogs()
          } catch (e) {
            this.$buefy.toast.open({
              message: `Error: ${e.message}`,
              type: 'is-danger',
              position: 'is-bottom'
            })
            console.log(e)
          }
        }
      })
    },
    async deleteResource (resourceId) {
      this.$buefy.dialog.confirm({
        title: 'Delete resource',
        message: `Are you sure you want to permanently delete the resource ${resourceId}?<br /><br />
          <strong>NOTE</strong>: This only deletes the metadata for the resources, not the actual data itself`,
        type: 'is-danger',
        onConfirm: async () => {
          try {
            await this.api.delete(
              `/resources/${encodeURIComponent(this.selectedCatalog.catalogId)}/${encodeURIComponent(resourceId)}`
            )
            await this.getCatalogs()
          } catch (e) {
            this.$buefy.toast.open({
              message: `Error: ${e.message}`,
              type: 'is-danger',
              position: 'is-bottom'
            })
            console.log(e)
          }
        }
      })
    },
    createReadOffer () {
      this.newArtifact.offer = `{
  "@context" : {
    "ids" : "https://w3id.org/idsa/core/",
    "idsc" : "https://w3id.org/idsa/code/"
  },
  "@type" : "ids:ContractOffer",
  "@id" : "https://w3id.org/idsa/autogen/contractOffer/${this.uuidv4()}",
  "ids:permission" : [ {
    "@type" : "ids:Permission",
    "@id" : "https://w3id.org/idsa/autogen/permission/${this.uuidv4()}",
    "ids:action" : [ {
      "@id" : "https://w3id.org/idsa/code/READ"
    } ]
  } ]
}`
    },
    uuidv4 () {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
        /[xy]/g,
        function (c) {
          const r = (Math.random() * 16) | 0
          const v = c === 'x' ? r : (r & 0x3) | 0x8
          return v.toString(16)
        }
      )
    }

  }
}
</script>
